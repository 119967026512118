import React from 'react';
import {LazyMotion, domAnimation, m} from 'framer-motion';

/**
 *
 * @param {props} - React props
 * @param {Array} props.items - partner image items
 * @param {string} props.direction - direction of effect
 * @param {string} props.speed - speed of effect
 * @returns
 */

const Marquee = ({items, direction, speed = 10}) => {
  let xDirection = direction === 'reverse' ? [-1200, 0] : [0, -1200];
  return (
    <LazyMotion features={domAnimation} strict>
      {/* widthAll = item * width(each item) */}
      <div className="flex h-[100px] w-[calc(200px*12)]">
        {items.map((item, idx) => (
          <m.div
            className="h-[80px] w-[200px]"
            key={idx}
            animate={{
              x: xDirection,
            }}
            transition={{
              x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration: speed,
                ease: 'linear',
              },
            }}
          >
            <m.img
              className="cursor-pointer"
              whileHover={{scale: 1.1}}
              src={item}
              alt="partner"
              height={80}
              width={200}
            />
          </m.div>
        ))}
      </div>
    </LazyMotion>
  );
};

export default Marquee;
