import {HiOutlineArrowLongRight} from '@react-icons/all-files/hi2/HiOutlineArrowLongRight';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import BoxWrapper from '../components/Wrapper/BoxWrapper';
import Button from '../components/_baseComponents/Button/Button';
import SectionHeading from '../components/_baseComponents/SectionHeading/SectionHeading';
import Marquee from '../components/_baseComponents/Animations/Marquee/Marquee';
import OneBitVentureImage from '../assets/home/partners/onebitVenture.png';
import CardWrapper from '../components/Wrapper/CardWrapper';
import {PartnerImages} from '../staticData/PartnerImages';
import Seo from '../components/Seo/Seo';
import {graphql, navigate} from 'gatsby';

const ButtonIcon = () => <HiOutlineArrowLongRight className="h-6 w-6" />;

const AboutPage = () => {
  const partnerImages = PartnerImages;
  // Duplicate Element in Array
  const partnerImagesForEffect = [...partnerImages, ...partnerImages];

  return (
    <div className="flex flex-col gap-12 pt-[70px] lg:gap-[100px] lg:pt-20">
      {/* Heading Section */}
      <BoxWrapper className="relative flex-col items-center pt-4 lg:pt-6">
        <StaticImage
          src="../assets/about/heading-section-image.jpeg"
          alt="Heading section image"
          className="h-28 w-full rounded-lg md:h-64 md:rounded-2xl lg:h-96 lg:rounded-3xl"
          imgClassName="rounded-lg md:rounded-2xl lg:rounded-3xl"
          objectFit="cover"
        />
        <div className="relative -top-8 flex w-64 gap-2 rounded-lg bg-white  px-3 py-2 shadow-md xs:w-72 md:-top-16 md:w-auto md:px-10 md:py-6 lg:absolute lg:-bottom-14 lg:top-80 lg:w-auto lg:gap-3 lg:px-10 lg:py-6">
          <div className="flex flex-col items-center justify-center gap-1">
            <p className="text-sm font-medium text-gray-700 md:text-lg">
              Tiên phong cung cấp
            </p>
            <h3 className="text-center text-base font-bolder text-gray-900 md:text-xl lg:text-2xl lg:text-gray-700">
              CÁC GIẢI PHÁP CHUYỂN ĐỔI SỐ CHO DOANH NGHIỆP
            </h3>
          </div>
        </div>
      </BoxWrapper>

      {/* Introduction */}
      <BoxWrapper
        className={
          'flex flex-col-reverse items-center justify-start gap-8 self-start md:flex-row md:gap-0 lg:w-full lg:gap-32'
        }
      >
        {/* Heading */}
        <div className="flex flex-col items-start justify-center gap-7 lg:gap-10">
          <SectionHeading
            content="Về chúng tôi"
            className="items-start lg:ml-0"
          />
          <p className="md:min-w-[300px] md:max-w-[50vw] lg:w-[480px] lg:max-w-none">
            Giữa bối cảnh hội nhập của nền kinh tế phẳng 4.0, KDIGI ra đời như
            một lẽ tất yếu với những hoài bão lớn lao trong sự nghiệp "Xây dựng
            giải pháp CNTT cho doanh nghiệp". Chúng tôi tự hào là một trong
            những đơn vị tiên phong hàng đầu trong lĩnh vực CNTT, xây dựng nên
            hàng trăm sản phẩm phần mềm chất lượng và hiệu quả tối ưu nhất (bao
            gồm Khối sản phẩm Nhà nước, Khối sản phẩm doanh nghiệp và Khối sản
            phẩm công nghệ mới) theo những cách riêng biệt đậm màu sắc KDIGI.
          </p>
          <Button
            type="primary"
            content="Liên hệ"
            Icon={ButtonIcon}
            className="w-fit gap-2 px-6 py-2 font-medium"
            onClick={() => navigate('/lien-he')}
          />
        </div>
        <div>
          {/* Bg effect (desktop only) */}
          <div className="relative left-36 hidden before:absolute before:-z-10 before:block before:h-[435px] before:w-[435px] before:rounded-full before:bg-blue-600/10 before:blur-[2rem] before:will-change-[filter] lg:flex" />
          {/* Bg effect (mobile & tablet only) */}
          <div className="relative -left-28 before:absolute before:-z-10 before:block before:h-[435px] before:w-[435px] before:rounded-full before:bg-blue-600/20 before:blur-[7.5rem]  before:will-change-[filter] lg:hidden" />
          <StaticImage
            src="../assets/about/introduce-man-sit-on-chair.png"
            alt="Man-sit-on-chair"
            width={800}
            className="w-[300px] flex-none md:w-[380px] lg:w-[500px]"
          />
        </div>
      </BoxWrapper>

      {/* Mission and vision */}
      <BoxWrapper className={'flex-col gap-4 md:flex-row md:gap-8'}>
        <CardWrapper
          className="flex flex-col gap-3 rounded-xl bg-white/40 p-5 md:gap-10"
          isShadow={false}
        >
          <h2 className="text-2xl font-bolder md:text-3xl lg:text-4xl">
            Sứ mệnh
          </h2>
          {/* Bg effect (mobile only) */}
          <div className="relative before:absolute before:-right-[0vw] before:-top-[16rem] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-red-400/50 before:blur-[7.5rem] before:will-change-[filter] md:hidden" />

          <p className="text-gray-700">
            Bằng tất cả tâm huyết, trí tuệ, kinh nghiệm và lòng nhiệt thành,
            KDIGI luôn sẵn sàng nghiên cứu, phát triển, cung cấp các giải pháp
            và dịch vụ CNTT tiên tiến nhất nhằm đáp ứng các yêu cầu khắt khe và
            mang lại sự hài lòng cao nhất cho quý khách hàng.
          </p>
        </CardWrapper>
        <CardWrapper
          className="flex flex-col gap-3 rounded-xl bg-white/40 p-5 md:gap-10"
          isShadow={false}
        >
          <h2 className="text-2xl font-bolder md:text-3xl lg:text-4xl">
            Tầm nhìn
          </h2>
          {/* Bg effect (mobile only) */}
          <div className="relative before:absolute before:-bottom-[30rem] before:-right-[0vw] before:-z-10 before:block before:h-[200px] before:w-[200px] before:rounded-full before:bg-cyan-600/50 before:blur-[7.5rem] before:will-change-[filter] md:hidden" />

          <p className="text-gray-700">
            Bằng tất cả tâm huyết, trí tuệ, kinh nghiệm và lòng nhiệt thành,
            KDIGI luôn sẵn sàng nghiên cứu, phát triển, cung cấp các giải pháp
            và dịch vụ CNTT tiên tiến nhất nhằm đáp ứng các yêu cầu khắt khe và
            mang lại sự hài lòng cao nhất cho quý khách hàng.
          </p>
        </CardWrapper>
      </BoxWrapper>

      {/* Why Choose us? */}
      <BoxWrapper className=" flex-col items-center gap-8 lg:gap-12">
        <SectionHeading content={'Tại sao chọn chúng tôi?'} />
        <div className="flex w-full flex-col gap-4 md:flex-row md:flex-wrap md:justify-between lg:flex-nowrap lg:justify-center lg:gap-8">
          <CardWrapper
            className="flex flex-col gap-1 rounded-lg bg-white/40 p-5 md:w-[48%] md:gap-2 lg:rounded-xl"
            isShadow={false}
          >
            <StaticImage
              src="../assets/about/mission-vision-image-1.png"
              alt="Elite resources"
              width={100}
              height={100}
              className="h-16 w-16"
            />
            {/* Bg effect */}
            <div className="relative before:absolute before:-bottom-[55rem] before:-right-[0vw] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-red-400/50 before:blur-[7.5rem] before:will-change-[filter] before:md:-bottom-[0rem] before:md:-left-[30vw] before:md:bg-red-400/30 before:md:blur-[13.5rem] before:lg:-right-[0vw] before:lg:-top-[35rem] before:lg:bg-red-400/20 before:lg:blur-[5rem]" />

            <div className="flex flex-col gap-3">
              <h3 className="text-lg font-bolder">Nguồn lực tinh nhuệ</h3>
              <p className="text-gray-500">
                KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số
                cho doanh nghiệp tại Việt Nam.
              </p>
            </div>
          </CardWrapper>
          <CardWrapper
            className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[48%] lg:rounded-xl"
            isShadow={false}
          >
            <StaticImage
              src="../assets/about/mission-vision-image-2.png"
              alt="Creative thinking"
              width={100}
              height={100}
              className="h-16 w-16"
            />
            <div className="flex flex-col gap-3">
              <h3 className="text-lg font-bolder">Tư duy sáng tạo</h3>
              <p className="text-gray-500">
                KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số
                cho doanh nghiệp tại Việt Nam.
              </p>
            </div>
          </CardWrapper>
          <CardWrapper
            className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[48%] lg:rounded-xl"
            isShadow={false}
          >
            <StaticImage
              src="../assets/about/mission-vision-image-3.png"
              alt="Professional service"
              width={100}
              height={100}
              className="h-16 w-16"
            />
            <div className="flex flex-col gap-3">
              <h3 className="text-lg font-bolder">Dịch vụ chuyên nghiệp</h3>
              <p className="text-gray-500">
                KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số
                cho doanh nghiệp tại Việt Nam.
              </p>
            </div>
          </CardWrapper>
          <CardWrapper
            className="flex flex-col gap-1 rounded-lg bg-white/40 p-5 md:w-[48%] md:gap-2 lg:rounded-xl"
            isShadow={false}
          >
            <StaticImage
              src="../assets/about/mission-vision-image-4.png"
              alt="Effective security"
              width={100}
              height={100}
              className="h-16 w-16"
            />
            {/* Bg effect */}
            <div className="relative before:absolute before:-left-[50vw] before:-top-[15rem] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-orange-400/40 before:blur-[7.5rem] before:will-change-[filter] md:hidden" />

            <div className="flex flex-col gap-3">
              <h3 className="text-lg font-bolder">Bảo mật hiệu quả</h3>
              <p className="text-gray-500">
                KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số
                cho doanh nghiệp tại Việt Nam.
              </p>
            </div>
          </CardWrapper>
        </div>
        {/* Bg effect */}
        <div className="relative before:absolute before:-left-[50vw] before:-top-[100rem] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-green-400/40 before:blur-[7.5rem] before:will-change-[filter] before:md:-left-[0vw] before:md:-top-[25rem] before:md:bg-green-400/30 before:md:blur-[13.5rem] before:lg:-left-[0vw] before:lg:-top-[15vw] before:lg:bg-green-400/10 before:lg:blur-[5rem]" />
      </BoxWrapper>

      {/* Partner */}
      <BoxWrapper className={'flex-col items-center gap-8 md:gap-10 lg:gap-5'}>
        {/* Heading */}
        <SectionHeading content="Đối tác & Khách hàng" />

        <section className="bg-section-partner 0 relative -left-5 m-auto mt-10 w-[960px] overflow-hidden bg-gray-400/0 py-3 md:-left-20 md:mt-0 lg:left-0 lg:h-[400px] ">
          <Marquee items={partnerImagesForEffect} speed={25} />
          <Marquee items={partnerImagesForEffect} speed={30} />

          {/* For desktop */}
          <div className="relative hidden w-full justify-center lg:flex">
            <div
              className="absolute -top-2 flex flex-col items-center justify-center gap-3 rounded-lg bg-white px-3 pb-3 pt-6 lg:w-[32rem] lg:flex-row lg:gap-5 lg:rounded-xl lg:p-0 "
              style={{
                boxShadow: '8px 8px 16px 2px rgba(213, 226, 233, 0.25)',
              }}
            >
              <div className="w-28 lg:w-64">
                <img
                  src={OneBitVentureImage}
                  height={'100px'}
                  width={'250px'}
                  alt=""
                />
              </div>

              <div className="">
                <Button
                  type="primary"
                  content="Xem Case Study"
                  Icon={ButtonIcon}
                  className="w-fit gap-2 px-6 py-2 font-medium"
                />
              </div>
            </div>
          </div>
        </section>

        {/* For mobile & tablet */}
        <div
          className="relative -top-14 flex flex-col items-center justify-center gap-3 rounded-lg bg-white p-3 md:-top-20 md:flex-row md:px-6 lg:hidden"
          style={{boxShadow: '8px 8px 16px 2px rgba(213, 226, 233, 0.25)'}}
        >
          <div className="w-28 md:w-48">
            <img
              src={OneBitVentureImage}
              height={'100px'}
              width={'250px'}
              alt=""
            />
          </div>

          <div className="">
            <Button
              type="primary"
              content="Xem Case Study"
              Icon={ButtonIcon}
              className="w-fit gap-2 px-6 py-2 font-medium"
            />
          </div>
        </div>
      </BoxWrapper>
    </div>
  );
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({data: {wpPage}}) => {
  return (
    <Seo
      yoastSeo={wpPage?.seo}
      title={wpPage?.title}
      uri={wpPage?.uri}
      description={wpPage?.content && wpPage.content.substring(150)}
    />
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    wpPage(uri: {eq: "/gioi-thieu"}) {
      id
      uri
      title
      content
      seo {
        fullHead
        canonical
      }
    }
  }
`;
